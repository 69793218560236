<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="team_id"
                  :not-list="false"
                  :options="teamList"
                  :title="$t('scope.Team') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  :isOpenMultiselect="true"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="company_id"
                  :not-list="false"
                  :options="customerOptions"
                  :title="$t('order.customer') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="order_company_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="represantative_id"
                  :not-list="false"
                  :options="represantatives"
                  :title="$t('Müşteri Temsilcisi') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="represantative_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="supplier_id"
                  :not-list="false"
                  :options="suppliers"
                  :title="$t('Üretici') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="supplier_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="order_id"
                  :not-list="false"
                  :options="orderOptions"
                  :title="$t('report.order_no') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="order_id"
                ></custom-multi-select>
              </div>
            </div>
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-lg-4 col-md-6 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <dashboard-box :title="$t('report.endorsemet_report')">
        <template v-slot:preview>
          <LoadingDeadlineReportChart
            :start-date.sync="filters.start_date"
            :end-date.sync="filters.end_date"
            :filters.sync="tempFilters"
            :onClickButton="onClickButton"
            :item-data="itemData"
            @revizeFilter="revizeFilter"
            @resetFilters="resetFilters"
            @totalDataList="totalDataList"
            @parentTableChange="parentTableChange"
            ref="refLoadingDeadlineReportChart"
          ></LoadingDeadlineReportChart>
        </template>
      </dashboard-box>
      <dashboard-box :title="$t('esc.esc_list')">
        <template v-slot:preview>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :striped="true"
              :bordered="false"
              :borderless="true"
              :fields="tableFields"
              :hover="false"
              :items="tempChange"
              :no-border-collapse="true"
              :outlined="false"
              :page.sync="page"
              :perPage.sync="perPage"
              :infiniteId="infiniteId"
              tableVariant="''"
              @hitBottom="onHitBottom"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              :table-parent-fields="tableParentFields"
              :packing-column-count="pacingColumn"
              :export-column-count="exportColumn"
              :disabled-infinite-loading="false"
              :sticky-header="true"
            ></data-table>
            <div class="row">
              <div
                class="general-area col-2 d-flex align-items-center justify-content-start align-content-start"
              >
                <div class="text-center">{{ $t("general.total") }}</div>
              </div>
              <div
                class="col-10 info-total-area align-items-center d-flex justify-content-end align-content-end"
              >
                <div class="col-3 text-center mr-7">
                  {{ $t("report.order_amount") }}:
                  <span class="total-area-text">
                    {{
                      Number(totalData.totalOrderAmount).toLocaleString(
                        "tr-TR",
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )
                    }}
                  </span>
                </div>
                <div class="col-3 text-center mr-7">
                  {{ $t("Zamanında") }}:

                  <span class="total-area-text">
                    {{
                      Number(totalData.totalZamanindaAmount).toLocaleString(
                        "tr-TR",
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )
                    }}
                  </span>
                </div>
                <div class="col-3 text-center mr-0">
                  {{ $t("Gecikme") }}:
                  <span class="total-area-text">
                    {{
                      Number(totalData.totalGecikmedeAmount).toLocaleString(
                        "tr-TR",
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )
                    }}
                  </span>
                </div>
                <div class="col-3 text-center mr-0">
                  {{ $t("Devam Eden") }}:
                  <span class="total-area-text">
                    {{
                      Number(
                        totalData.totalDevamEdenlerAmount
                      ).toLocaleString("tr-TR", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTableforReport";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import LoadingDeadlineReportChart from "./LoadingDeadlineReportChart";

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  SET_CURRENT_ITEM,
  FILTER,
} from "@/core/services/store/report/loadingDeadlineReport.module";

import { GET_ITEMS } from "@/core/services/store/REST.module";
import {
  LARAVEL_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_TIME_FORMAT,
} from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME,
  LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

export default {
  name: "LoadingDeadlineReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect,
    ContextMenu,
    LoadingDeadlineReportChart,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    yearOptions() {
      const currentYear = new Date().getFullYear();
      const years = [];
      const yearsToGenerate = 60;

      for (let i = 0; i < yearsToGenerate; i++) {
        const year = currentYear - i;
        years.push({ id: year, name: year });
      }
      return this.convertArrayToObjectByKey(years, "id", "name");
    },
    formattedReport:{
      get: function () {
        if (
          !this.items ||
          this.items.data === null ||
          this.items.data === undefined
        )
          return [];
        let temp = [];
        this.itemData = this.items.data;
        this.items.data.forEach((item) => {
          let packArr = [];
          let exportArr = [];
          let exportDateArr = [];
          let packDateArr = [];
          let exportAmountArr = [];
          let packAmountArr = [];

          item.packing.forEach((value, index) => { 
            packDateArr.push(value.created_at);
            packAmountArr.push(value.amount);
            packArr["packing_date_" + (index + 1)] = moment(
              value.created_at
            ).format(MOMENT_SYSTEM_DATE_FORMAT);
            packArr["packing_amount_" + (index + 1)] = value.amount;
          });
          item.order_export_dates.forEach((value, index) => {
            exportDateArr.push(value.export_date);
            exportAmountArr.push(value.amount);
            exportArr["export_date_" + (index + 1)] = moment(
              value.export_date
            ).format(MOMENT_SYSTEM_DATE_FORMAT);
            exportArr["export_amount_" + (index + 1)] = value.amount;
          });

          let exportPush =[];
          let deadlinePush =[];
          item.order_export_dates.forEach((value, index) => {
            deadlinePush.push({'amount':value.amount,'created_at':value.export_date})
          })
          item.packing.forEach((value, index) => { 
            exportPush.push({'amount':value.amount,'created_at':value.created_at})
          })  
 
          //renklendirme 
          // let isAnyPackDateGreaterThanExport =0;
          let isAnyPackDateGreaterThanExport = this.compareDates(deadlinePush,exportPush,item.status_id)
          // for (let i = 0; i < exportDateArr.length; i++) {
          //   let exportDate = new Date(exportDateArr[i]);
          //   let exportAmount = Number(exportAmountArr[i]);
          //   if(item.status_id == 5){
          //     isAnyPackDateGreaterThanExport = 1;
          //   }else{ 
          //     for (let j = 0; j < packDateArr.length; j++) {
          //       let packDate = new Date(packDateArr[j]);
          //       let packAmount = Number(packAmountArr[j]);
          //       if ( packDate >= exportDate ) {
          //           if(packAmount >= exportAmount){
          //             isAnyPackDateGreaterThanExport = 0;
          //           }else{
          //             isAnyPackDateGreaterThanExport = 1;
          //           }
          //       }else{
          //         isAnyPackDateGreaterThanExport = 1;
          //       }
          //       if (exportDate != null && packDate == null) {
          //         isAnyPackDateGreaterThanExport = 2;
          //       }
          //       console.log(isAnyPackDateGreaterThanExport)
          //     }
          //   }
          // }

          temp.push({
            customer: item.company.name,
            order_number: item.order_number,
            ...packArr,
            ...exportArr,
            row_status_and_color: isAnyPackDateGreaterThanExport,
          });
        });
        this.tempChange =temp
        return temp;
      }
    }, 
    tableFields() {
      let list = [
        {
          key: "order_number",
          label: "Sipariş No",
          class: "text-center border border-top-0 border-left-0 text-nowrap",
          tdClass: "cursor-pointer",
          isRowHeader: true,
          stickyColumn: true,
        },
        {
          key: "customer",
          label: "Müşteri",
          class: "text-center border border-top-0 border-left-0 text-nowrap",
          tdClass: "cursor-pointer",
          isRowHeader: true,
        },
      ];
      if (this.itemData && this.itemData.length > 0) {
        const packingData = [];
        const exportData = [];
        let maxExportCount = 0;
        let maxPackingCount = 0;
        this.itemData.forEach((item) => {
          packingData.push(item.packing.length);
          exportData.push(item.order_export_dates.length);
        });
        if (packingData.length > 0) {
          maxPackingCount = Math.max(...packingData);
        }
        if (exportData.length > 0) {
          maxExportCount = Math.max(...exportData);
        }

        this.pacingColumn = maxPackingCount;
        this.exportColumn = maxExportCount;

        for (let index = 1; index <= maxExportCount; index++) {
          list.push({
            key: "export_date_and_amount_" + index,
            label: index + ". Termin Tarihi/Adet",
            class: "text-center border border-top-0 border-left-0 text-nowrap custom_font_size2", 
            tdClass: "cursor-pointer",
          });
        }
        for (let index = 1; index <= maxPackingCount; index++) {
          list.push({
            key: "packing_date_and_amount_" + index,
            label: index + ". Yükleme Tarihi/Adet",
            class: "text-center border border-top-0 border-left-0 text-nowrap", 
            tdClass: "cursor-pointer",
          });
        }
      }

      return list;
    },
    tableParentFields() {
      let list = [
        {
          parent: true,
          label: "#",
          colspan: 2,
          class: "text-center border border-top-0 border-left-0 text-nowrap",
          tdClass: "cursor-pointer",
        },
        {
          parent: true,
          label: "Terminler",
          colspan: this.exportColumn,
          class: "text-center border border-top-0 border-left-0 text-nowrap custom_font_size", 
          tdClass: "cursor-pointer",
        },
        {
          parent: true,
          label: "Yüklemeler",
          colspan: this.exportColumn,
          class: "text-center border border-top-0 border-left-0 text-nowrap",
          tdClass: "cursor-pointer",
        },
      ];
      return list;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      sort: "desc",
      order: "id",
      sampleStatuses: [],
      fieldsToViewEdit: [],
      customerOptions: [],
      represantatives: [],
      suppliers: [],
      search: null,
      paginate: [],
      orderSizeAndColorExportDateBodySizes: [],
      page: 1,
      perPage: 25,
      currentStoreType: 99999999,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      download: false,
      reportListUrl: "api/report/loading-deadline",
      currencyRateOptions: [],
      companies: [],
      orderOptions: [],
      teamList: [],
      tmpOrderData: [],
      tmpPackingListData: [],
      moment: moment,
      onClickButton: false,
      exportColumn: 2,
      pacingColumn: 2,
      months: [
        {
          id: "01",
          name: this.$t("dateTime.january_full"),
        },
        {
          id: "02",
          name: this.$t("dateTime.february_full"),
        },
        {
          id: "03",
          name: this.$t("dateTime.march_full"),
        },
        {
          id: "04",
          name: this.$t("dateTime.april_full"),
        },
        {
          id: "05",
          name: this.$t("dateTime.may_full"),
        },
        {
          id: "06",
          name: this.$t("dateTime.june_full"),
        },
        {
          id: "07",
          name: this.$t("dateTime.july_full"),
        },
        {
          id: "08",
          name: this.$t("dateTime.august_full"),
        },
        {
          id: "09",
          name: this.$t("dateTime.september_full"),
        },
        {
          id: "10",
          name: this.$t("dateTime.october_full"),
        },
        {
          id: "11",
          name: this.$t("dateTime.november_full"),
        },
        {
          id: "12",
          name: this.$t("dateTime.december_full"),
        },
      ],
      itemData: [],
      teamListData: [],
      tmpRep: [],
      team_id: [],
      supplier_id: [],
      represantative_id: [],
      company_id: [],
      order_id: [],
      tempFilters: {},
      totalData: [],
      tempChange:{}
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    revizeFilter(filters){
      if (filters && this.filters.length > 0 ) { 
        this.team_id = filters.team_id !=null && filters.team_id.length > 0 ? JSON.parse(filters.team_id) : []
        this.company_id = filters.company_id !=null && filters.company_id.length > 0 ? JSON.parse(filters.company_id) : [] 
        this.filterResult();
      }
    }, 
    compareDates(terminData, yuklemeData, statusID) {
        // Helper function to sum amounts by date
        const sumByDate = (data) => {
            if (!Array.isArray(data)) {
                throw new TypeError("Expected an array");
            }

            return data.reduce((acc, curr) => {
                const date = curr.created_at.split('T')[0]; // Extract date part
                acc[date] = (acc[date] || 0) + Number(curr.amount); 
                return acc;
            }, {});
        };

        // Tarihe göre tutarların toplanması
        const terminTotals = sumByDate(terminData);
        const yuklemeTotals = sumByDate(yuklemeData);
        let result = 0; 
 
        if(Object.keys(yuklemeTotals).length == 0){
          result=2;
        }
        
        // Toplamları karşılaştırma  
        for (const [terminDate, terminAmount] of Object.entries(terminTotals)) {
            let terminSum=0;
            const terminDateObj = new Date(terminDate);

            let yuklemeSum = 0;
            let sonYuklemeDateObj =''
            const now = new Date();
            let firstCheck = true; // İlk kontrol için bir değişken tanımla
            for (const [yuklemeDate, yuklemeAmount] of Object.entries(yuklemeTotals)) {
              const yuklemeDateObj = new Date(yuklemeDate);
              if(yuklemeDateObj){ 
                // İlk kontrol yapılıyor ve yuklemeDate terminDate'den büyükse
                if (firstCheck && yuklemeDateObj.getTime() > terminDateObj.getTime()) {
                  console.log( yuklemeDateObj.getTime(),terminDateObj.getTime(),yuklemeDateObj.getTime() > terminDateObj.getTime());
                  return result = 1; 
                }
                firstCheck = false;
 
                if (yuklemeDateObj.getTime() > terminDateObj.getTime()) {
                  if(statusID ==4){
                    if (yuklemeAmount && terminAmount > yuklemeAmount) {
                      result =1;
                    }else{
                      result = 2;
                    }
                  }else{
                    result = 1;
                  } 
                }else if(yuklemeDateObj.getTime() == terminDateObj.getTime()){
                  if ( (Math.abs(yuklemeAmount - terminAmount) / (yuklemeAmount==0 ? 1 :yuklemeAmount))  < 0.05) {
                    result =0;
                  }else{
                    if(statusID ==4){
                      result = 2;
                    }else{  
                      result = 1; 
                    }
                  }
                }else{ 
                  console.log('BURADA'); 
                  result = 0; 
                }                
                yuklemeSum += yuklemeAmount;
              }else{
                result =2;
              }
              sonYuklemeDateObj = yuklemeDateObj;
            }
            console.log('FOREACH CIKIS',result);
            terminSum +=terminAmount 
            if (yuklemeSum > 0 && yuklemeSum <=  terminSum) {
              if ( (Math.abs(yuklemeSum - terminSum) / (yuklemeSum==0 ? 1 :yuklemeSum))  < 0.05) {
                if(terminDateObj<sonYuklemeDateObj){
                  result =1;
                }else{
                  result =0;
                } 
              }else{
                if(statusID ==4){
                  if (yuklemeSum && terminAmount > yuklemeSum) {
                    result =1;
                  }else{
                    result = 2;
                  } 
                }else{  
                  result = 1; 
                }
              } 
            }
        }
  
        return result;
    },
    filterResult() {
      localStorage.setItem(
        LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.onHitBottom();
      // this.getTotalAmount();
      this.$refs.refLoadingDeadlineReportChart.closeDonut(); 
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      
      if (this.team_id) {
        this.$set(filters, "team_id", JSON.stringify(this.team_id));
      }
      if (this.supplier_id) {
        this.$set(filters, "supplier_id", JSON.stringify(this.supplier_id));
      }
      if (this.represantative_id) {
        this.$set(
          filters,
          "represantative_id",
          JSON.stringify(this.represantative_id)
        );
      }
      if (this.company_id) { 
        this.$set(filters, "company_id", JSON.stringify(this.company_id));
      }
      if (this.order_id) {
        this.$set(filters, "order_id", JSON.stringify(this.order_id));
      }
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);

      this.tempFilters = filters;
       
      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;
          if (result.status) {
            this.parentTableChange();
            if ($state) {
              this.infiniteId++;
              $state.loaded();
            }
          } else {
            if ($state) {
              this.infiniteId++;
              $state.complete();
            }
          }
        });
    },
    resetFilters() {
        (this.sort = "desc"),
        (this.order = "id"),
        (this.team = null),
        this.setFilter({
          page: 1,
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });
        (this.team_id = []),
        (this.supplier_id = []),
        (this.represantative_id = []),
        (this.company_id = []),
        (this.order_id = []),
        (this.tempFilters = {});
    },
    setup() {
      let self = this,
      promises = [];
  
      promises.push(
        this.$store.dispatch(GET_ITEMS, { 
          url: "api/companies",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/currency-rate",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/report/order-list-packing?",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/team-list",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/supplier-list",
        })
      );

      // Müşteri temsilcisi
      if (this.filters.represantative_id) {
        let url = "api/representative-list" + this.filters.represantative_id;
        promises.push(
          this.$store.dispatch(GET_ITEMS, {
            url: url,
          })
        );
      } else {
        let url = "api/representative-list";
        promises.push(
          this.$store.dispatch(GET_ITEMS, {
            url: url,
          })
        );
      }

      Promise.all(promises).then((results) => {
        if (results[0].status) {
          self.customerOptions = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "name"
          );
        }
        if (results[1].status) {
          self.companies = this.currencyRateOptions = results[1].data.data;
        }

        if (results[2].status) {
          this.orderOptions = results[2].data;
        }

        if (results[3].status) {
          this.teamListData = results[3].data;
          self.teamList = self.convertArrayToObjectByKey(
            results[3].data,
            "id",
            "user_name"
          );
        }
        if (results[4].status) {
          self.suppliers = self.convertArrayToObjectByKey(
            results[4].data,
            "id",
            "name"
          );
        }

        if (results[5].status) { 
          self.tmpRep = self.convertArrayToObjectByKey(
            results[5].data,
            "id",
            "user_name"
          ); 
          self.represantatives = self.convertArrayToObjectByKey(
            results[5].data,
            "id",
            "user_name"
          );
        }
      }); 
    },
    totalDataList(data){ 
      this.totalData = data; 
    },
    teamMembers() {
      if (!this.filters.team_id) return [];
      const team = this.teamListData.find(
        (team) => team.id == this.filters.team_id
      );
      return team ? team.members : [];
    },
    getTotalAmount() {
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);
      this.$set(filters, "order_id", JSON.stringify(filters.order_id));
      if (this.team_id) {
        this.$set(filters, "team_id", JSON.stringify(this.team_id));
      }
      if (this.supplier_id) {
        this.$set(filters, "supplier_id", JSON.stringify(this.supplier_id));
      }
      if (this.represantative_id) {
        this.$set(
          filters,
          "represantative_id",
          JSON.stringify(this.represantative_id)
        );
      }
      if (this.company_id) {
        this.$set(filters, "company_id", JSON.stringify(this.company_id));
      } 
    },
    onContextMenu(event) {
      event.preventDefault();
    },
    orderFilter(param){      
      let params=''; 
      if(param.team_id){
        params += '&team_id='+JSON.stringify(param.team_id);
      }
      if(param.company_id ){
        params += '&company_id='+JSON.stringify(param.company_id);
      } 
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/report/order-list-packing?"+params, 
        })
        .then((result) => { 
          if (result.status) {
            this.orderOptions = result.data
          } else {
            console.log({ error: result });
          }
        });
    },
    parentTableChange(type){ 
      this.tempChange = this.formattedReport;
      if(type=='in_time'){ 
        this.tempChange =  this.tempChange.filter(item => item.row_status_and_color === 0);
      }else if(type=='delay'){
        this.tempChange =  this.tempChange.filter(item => item.row_status_and_color === 1);
      }else{
        this.tempChange = this.formattedReport;
      } 
    }
  },
  async created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {}); 
  },
  async mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.loading_deadline_report") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(
          localStorage.getItem(LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME)
        )
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(
          localStorage.getItem(LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME)
        )
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();
    // this.getTotalAmount();

    setTimeout(this.onHitBottom, 500);
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
    "team_id"(value){ 
      this.orderFilter({'team_id':value})      
    },
    "company_id"(value){ 
      this.orderFilter({'company_id':value})      
    },
  },
};
</script>

<style scoped>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px 10px 20px;
}
.card.card-custom > .card-body {
  padding: 0.3rem 0.3rem !important;
  margin: 0px !important;
}
.order-number-text {
  padding: 11px;
  margin-left: -26px;
  margin-top: -17px;
  padding-left: 25px;
  padding-right: 25px;
  color: white !important;
  border-radius: 5px;
  background: linear-gradient(
    180deg,
    rgb(102, 199, 232) 0%,
    rgb(64, 138, 198) 35%,
    rgb(70, 41, 133) 100%
  ) !important;
}

.vm--container > .vm--overlay > .vm--modal {
  border-radius: 15px !important;
}
.close-button {
  color: #45318a !important;
}
.close-button:hover {
  cursor: pointer;
  color: #694ec7 !important;
}
.info-area {
  padding: 15px 5px 5px 5px;
  margin: 15px 5px 5px 0px;
  border: 2px solid #e6e6e6;
  border-radius: 12px;
}
.planned-text {
  position: absolute;
  text-decoration: underline;
  text-underline-offset: 4px;
  top: 4px;
}
.planned-table,
th,
td {
  border: 2px solid #e6e6e6;
}
.card-inner-area {
  margin: 0px !important;
  padding: 0px !important;
}
.show-button {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  height: 30px;
}
.show-button:hover {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  height: 30px;
}
.active-show-button {
  color: #fff;
  background: linear-gradient(
    180deg,
    rgb(102, 199, 232) 0%,
    rgb(64, 138, 198) 35%,
    rgb(70, 41, 133) 100%
  ) !important;
}
.active-eye-button {
  color: rgb(36, 22, 67) !important;
}
.eye-icon {
  color: rgb(158, 158, 158);
}
.main-title {
  text-decoration: underline;
  text-underline-offset: 4px;
}
.main-title > span {
  font-weight: 400;
  font-size: 12;
}
/* Scrollbar tasarımı */
.table-responsive {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.table-responsive::-webkit-scrollbar {
  height: 8px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>


<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}

.info-total-area {
  margin-bottom: 1%;
  margin-right: -1%;
  padding-right: 5%;
}
.info-total-area > div {
  flex: 0 0 17%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.general-area {
  margin-bottom: 1%;
  padding-left: 2%;
}
.general-area > div {
  flex: 1;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.total-area-text {
  font-size: 13px;
  font-weight: 400;
}
</style>
<style lang="scss">
.b-table-sticky-header > .table.b-table > thead > tr > th {
  background-color: white !important;
}
.b-table-sticky-header {
  max-height: 500px;
}
.b-table-sticky-header > .table.b-table > tbody > tr > th {
  background-color: white !important;
}

// .b-table-sticky-header > .table.b-table > thead > tr > th:nth-child(2){
//   border-right:3px solid  grey !important; 
// }

.custom_font_size{
  font-size:16px !important;
}
.custom_font_size2{
  font-size:14px !important;
}

</style>

